import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const CopyableValue = ({ width= 17,  value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      })
      .catch((err) => console.error("Failed to copy!", err));
  };

  return (
    <IconButton
      onClick={handleCopy}
      style={{
        padding: 0,
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ContentCopyIcon color={copied ? "disabled" : "primary"} style={{width: `${width}px`}}/>
    </IconButton>
  );
};

export default CopyableValue;
