import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SelectSearch from '../elements/select-search';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getDataRequestCreate } from '../../_actions_';
import InputField from '../elements/input';
import { PER_PAGE, productLinkStatus, targetMarketOption } from '../../_constants_';
import { getProductLink, updateProductLinks } from '../../_actions_/product_link.actions';

export default function EditProductLink(props) {
  const { modal, onClose, productLink } = props;
  const [dataForm, setDataForm] = useState({});
  const [errorForm, setErrorForm] = useState('');
  const dataCreate = useSelector((state) => state?.adAccount?.dataCreate);
  const dispatch = useDispatch();
  const dataEditSuccess = useSelector((state) => state?.productLink?.dataEditSuccess);
  const editErrors = useSelector((state) => state?.productLink?.editErrors);
  const isInReview = productLink.team_id && productLink?.status === productLinkStatus.process;

  useEffect(() => {
    if (!dataCreate) {
      dispatch(getDataRequestCreate());
    }
  }, []);

  useEffect(() => {
    if (productLink) {
      setDataForm({
        industry: productLink.industry?.value,
        url: productLink.url ?? productLink.url_in_ad,
        target_market: +productLink.target_market
      });
    }
  }, [productLink]);

  useEffect(() => {
    if (dataEditSuccess && productLink.team_id) {
      dispatch(
        getProductLink({
          page: 1,
          per_page: PER_PAGE,
          team_id: productLink.team_id
        })
      );
      handleClose();
      dispatch(clearData('dataEditSuccess'));
    }
  }, [dataEditSuccess]);

  useEffect(() => {
    if (editErrors) {
      setErrorForm(editErrors.msg.errors);
      dispatch(clearData('editErrors'));
    }
  }, [editErrors]);

  const handleSubmit = () => {
    dispatch(
      updateProductLinks(productLink.id, {
        ...dataForm,
        status: productLink.status
      })
    );
  };

  const handleClose = () => {
    onClose();
    setDataForm({});
    if (errorForm) setErrorForm('');
  };

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  const btnSubmit = useMemo(() => {
    const isValid = dataForm.industry && dataForm.url && dataForm.target_market;
    if (!isInReview) return null;
    return (
      <DialogActions sx={{ mr: 1 }}>
        <Button
          className="mr-2"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          style={{ borderRadius: 4 }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={isValid ? handleSubmit : null}
          style={{ borderRadius: 4 }}
        >
          Save
        </Button>
      </DialogActions>
    );
  }, [productLink, dataForm]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="modal-form-dialog"
      open={modal}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle id="modal-form-dialog" sx={{ m: 0, p: '16px 32px' }}>
        <Box display="flex" gap={1} justifyContent={'space-between'}>
          <Box>Product Link Detail</Box>
          <Box
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.5
              },
              mr: -2
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2} p={1}>
          <SelectSearch
            disabled={!isInReview}
            title={'Product Type'}
            placeholder={'Select a product type'}
            value={dataForm.industry}
            onChange={(newValue) => {
              handleChangeDataForm('industry', newValue ? newValue.value : '');
            }}
            options={dataCreate?.industry ?? []}
            required
            error={errorForm.industry}
          />

          <InputField
            disabled={!isInReview}
            title="Product URLs"
            placeholder="Enter your product url"
            value={dataForm.url}
            onChange={(event) => handleChangeDataForm('url', event.target.value)}
            required
            error={errorForm.url}
          />

          <SelectSearch
            disabled={!isInReview}
            title={'Target Market'}
            placeholder={'Select a target market'}
            value={dataForm.target_market}
            onChange={(newValue) => {
              handleChangeDataForm('target_market', newValue ? newValue.value : '');
            }}
            options={targetMarketOption}
            required
            error={errorForm.target_market}
          />
        </Box>
      </DialogContent>
      {btnSubmit}
    </Dialog>
  );
}
