/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomCircularProgress, Scrollbar } from '../index';
import { getAdsTransactionHistory } from '../../_actions_';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { ACCOUNT_HISTORY_TRANSFER_TYPE, PAGINATE_ARR, PER_PAGE } from '../../_constants_';
import {
  formatDateToTimezone,
  formatNumberTo4Character,
  formatNumberWithCommas,
  getColorBalance,
  getColorRowTransaction
} from 'src/_helpers_';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

const AdAccountTransactionHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataTransactionHistories = useSelector((state) => state?.payment?.dataTransactionHistories);
  const user = useSelector((state) => state?.authentication?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team_id: user?.team_id?.id
  });

  useEffect(() => {
    if (!dataTransactionHistories) {
      dispatch(getAdsTransactionHistory(queryObject));
    }
  }, []);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getAdsTransactionHistory({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getAdsTransactionHistory({ ...queryObject, per_page: event.target.value }));
  };

  return (
    <Box>
      <Scrollbar>
        <div className="mt-2" style={{ border: '1px solid #ccc', borderRadius: 6 }}>
          <Paper
            className={classes.root}
            style={{ position: 'relative', borderRadius: '6px 6px 0px 0px' }}
          >
            <TableContainer style={{ maxHeight: '50vh' }} className={classes.table}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '9rem' }}
                    >
                      <Tooltip title={'Transaction ID'} arrow placement="top">
                        <span className="line-clamp-1">Transaction ID</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Transaction Time'} arrow placement="top">
                        <span className="line-clamp-1">Transaction Time</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Ad account ID'} arrow placement="top">
                        <span className="line-clamp-1">Ad account ID</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Ad account name'} arrow placement="top">
                        <span className="line-clamp-1">Ad account name</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Amount USD'} arrow placement="top">
                        <span className="line-clamp-1">Amount USD</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Free credits'} arrow placement="top">
                        <span className="line-clamp-1">Free credits</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Discounts'} arrow placement="top">
                        <span className="line-clamp-1">Discounts</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '13rem' }}
                    >
                      <Tooltip title={'Operator'} arrow placement="top">
                        <span className="line-clamp-1">Operator</span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTransactionHistories?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                {formatNumberTo4Character(item?.id)}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                {formatDateToTimezone(item.transaction_date)}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                {item?.ads_acc_tiktok?.advertiser_id}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                {item?.ads_acc_tiktok?.name}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                {item?.transaction_type === ACCOUNT_HISTORY_TRANSFER_TYPE.RECHARGE ? (
                                  formatNumberWithCommas(item.transaction_amount, true)
                                ) : (
                                  `- ${formatNumberWithCommas(item.transaction_amount, true)}`
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                --
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <div
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <div className={classes.content_inline}>
                            <span className="text_2">
                              <div
                                className={classes.text_ellipsis}
                                style={{ color: getColorRowTransaction(item.transaction_type) }}
                              >
                                --
                              </div>
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline} style={{ color: getColorRowTransaction(item.transaction_type) }}>
                            <Box display={'flex'} gap={0.5} flexDirection={'column'} p={1}>
                              {item?.create_user?.email}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255,255,255,0.8)'
                }}
              >
                <div className="p-3 d-flex align-items-center justify-content-center">
                  <CustomCircularProgress />
                </div>
              </div>
            )}
          </Paper>
          {dataTransactionHistories && (
            <TablePagination
              className="list-pagination"
              style={{
                borderTop: '1px solid #D8D8D8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              component="div"
              count={dataTransactionHistories?.total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={queryObject.page - 1}
              rowsPerPage={queryObject.per_page}
              rowsPerPageOptions={PAGINATE_ARR}
            />
          )}
        </div>
      </Scrollbar>
    </Box>
  );
};

export default AdAccountTransactionHistory;
