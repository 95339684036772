import {
  Box,
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Select from '@mui/material/Select';
import { BG_COLOR_GREEN, COLOR_WHITE, PAGINATE_ARR, AD_FILTER, PER_PAGE } from '../../_constants_';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { clearData, getListRequest } from '../../_actions_';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { formatNumberWithCommas, getButtonColor } from '../../_helpers_';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function OverviewMyAdsAccount(props) {
  const { overviewAll } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const listRequests = useSelector((state) => state?.adAccount?.listRequests);
  const user = useSelector((state) => state?.authentication?.user);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team_id: overviewAll ? 0 : user?.team_id?.id,
    status: AD_FILTER[0].value
  });
  const location = useLocation();

  useEffect(() => {
    if (!listRequests) {
      dispatch(getListRequest(queryObject));
    }
  }, [listRequests]);

  useEffect(() => {
    if (listRequests) dispatch(clearData('listRequests'));
  }, [location]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getListRequest({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getListRequest({ ...queryObject, page: 1, per_page: event.target.value }));
  };

  return (
    <Box className="card-container" sx={{ marginBottom: '16px' }}>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        sx={{
          borderRadius: '8px',
          overflow: 'hidden',
          justifyContent: 'space-between'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0}
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            width: '30%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                height: '45px',
                backgroundColor: '#fff'
              },
              width: '100%',
              borderRight: 'none'
            }}
            value={queryObject.query}
            onChange={(event) => {
              setQueryObject((prev) => ({
                ...prev,
                query: event.target.value
              }));
            }}
          />
          <Button
            variant="contained"
            sx={{
              height: '45px',
              backgroundColor: BG_COLOR_GREEN,
              color: '#fff',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              '&:hover': {
                backgroundColor: '#007b55'
              }
            }}
            onClick={() => dispatch(getListRequest(queryObject))}
          >
            Search
          </Button>
        </Box>
        <Select
          value={queryObject.status}
          onChange={(event) => {
            setQueryObject((prevState) => ({
              ...prevState,
              status: event.target.value
            }));
            dispatch(getListRequest({ ...queryObject, page: 1, status: event.target.value }));
          }}
          sx={{ minWidth: 250 }}
        >
          {AD_FILTER.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Paper className={classes.root} style={{ position: 'relative', borderTop: '1px solid #ccc' }}>
        <TableContainer className={classes.table} style={{ maxHeight: '25vh' }}>
          <Table
            stickyHeader
            className={clsx(
              classes.dynamic_col,
              classes.dynamic_sticky,
              classes.table,
              'dynamic_col'
            )}
          >
            <TableHead className={classes.head_white}>
              <TableRow>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Account name'} arrow placement="top">
                    <Box className="line-clamp-1">Account name</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Account ID'} arrow placement="top">
                    <Box className="line-clamp-1">Account ID</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Team ID'} arrow placement="top">
                    <Box className="line-clamp-1">Team ID</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '14rem' }}
                >
                  <Tooltip title={'Account status'} arrow placement="top">
                    <Box className="line-clamp-1">Account status</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Business center name'} arrow placement="top">
                    <Box className="line-clamp-1">Business center name</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Business ID'} arrow placement="top">
                    <Box className="line-clamp-1">Business ID</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Time zone'} arrow placement="top">
                    <Box className="line-clamp-1">Time zone</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Balance'} arrow placement="top">
                    <Box className="line-clamp-1">Balance</Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listRequests?.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box display={'flex'} gap={1}>
                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            <Typography fontSize={14}>{item.name ?? '--'}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box display={'flex'} gap={1}>
                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            <Typography fontSize={14}>{item.advertiser_id ?? '--'}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box display={'flex'} gap={1}>
                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            <Typography
                              fontSize={14}
                            >{`Team_${item?.team_info?.team_id}`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Button
                        sx={{
                          cursor: 'unset !important',
                          borderRadius: '12px',
                          fontSize: 14,
                          color: COLOR_WHITE,
                          borderColor: COLOR_WHITE,
                          width: '150px',
                          textTransform: 'none',
                          backgroundColor: `${getButtonColor(item.status_display)}!important`
                        }}
                        variant="outlined"
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}>{item.status_display}</Box>
                          </Box>
                        </Box>
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box display={'flex'} gap={1}>
                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            <Typography fontSize={14}>{item.bc_name}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box display={'flex'} gap={1}>
                          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            <Typography fontSize={14}>{item.customer_bc_id}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box className="text_2">
                          <Box className={classes.text_ellipsis}> {item.timezone}</Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.cell_entry)}>
                    <Box className="fnu-view" style={{ position: 'relative', overflow: 'hidden' }}>
                      <Box className={classes.content_inline}>
                        <Box className="text_2">
                          <Box className={classes.text_ellipsis}>
                            {formatNumberWithCommas(item.balance, true)}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {listRequests && (
        <TablePagination
          className="list-pagination"
          style={{
            borderTop: '1px solid #d8d8d8',
            display: 'flex',
            alignItems: 'center'
          }}
          component="div"
          count={listRequests?.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={queryObject.page - 1}
          rowsPerPage={queryObject.per_page}
          rowsPerPageOptions={PAGINATE_ARR}
        />
      )}
    </Box>
  );
}
