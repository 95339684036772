import { Box, Link, ListItemText, Typography } from '@mui/material';
import React, { memo } from 'react';
import { COLOR_GRAY, COLOR_GRAY_BLUE, COLOR_YELLOW, PaymentMethod } from 'src/_constants_';
import { formatNumberWithCommas } from 'src/_helpers_';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CopyableValue from '../elements/copyable-value';

// METHOD: payoneer + pingpong
const BalanceSummaryMail = ({ transactionAmount = 0, paymentMethod, config, content }) => {
  const {
    payoneer_fee_per_transaction: payoneerFeePerTransaction = 0,
    payoneer_fee_topup: payoneerPaymentFee = 0,
    ping_pong_fee_per_transaction: pingpongFeePerTransaction = 0,
    ping_pong_fee_topup: pingpongPaymentFee = 0,
    service_fee: taxFee = 0
  } = config || {};

  // Get payment info based on method
  const getPaymentInfo = (method) => {
    const paymentInfo = {
      [PaymentMethod.payoneer]: {
        feePerTransaction: Number(payoneerFeePerTransaction),
        paymentFee: Number(payoneerPaymentFee),
        label: 'Payoneer',
        icon: '/static/icons/payoneer.png'
      },
      [PaymentMethod.pingpong]: {
        feePerTransaction: Number(pingpongFeePerTransaction),
        paymentFee: Number(pingpongPaymentFee),
        label: 'PingPong',
        icon: '/static/icons/pingpong.ico'
      }
    };

    return (
      paymentInfo[method] || {
        feePerTransaction: 0,
        paymentFee: 0,
        label: '',
        icon: null
      }
    );
  };

  // Calculate amounts
  const {
    feePerTransaction,
    paymentFee,
    label: methodLabel,
    icon: methodIcon
  } = getPaymentInfo(paymentMethod);
  const amount = Number(transactionAmount) || 0;
  const paymentFeeAmount = (amount * paymentFee) / 100;
  const taxAmount = (amount * Number(taxFee)) / 100;
  const totalAmount = amount + paymentFeeAmount + taxAmount + feePerTransaction;

  // Prepare summary items
  const summaryItems = [
    {
      label: 'Amount',
      value: `${formatNumberWithCommas(amount)} USD`
    },
    {
      label: 'Payment Method',
      value: methodLabel,
      icon: methodIcon
    },
    {
      label: `${methodLabel} Fee: (${paymentFee}%)`,
      value: `${formatNumberWithCommas(paymentFeeAmount)} USD`
    },
    {
      label: 'Processor fee per transaction',
      value: `${formatNumberWithCommas(feePerTransaction)} USD`
    },
    {
      label: `Tax: (${taxFee}%)`,
      value: `${formatNumberWithCommas(taxAmount)} USD`
    }
  ];

  return (
    <>
      {/* ------------------- Summary Section ------------ */}
      <Box sx={{ backgroundColor: COLOR_GRAY, borderRadius: '8px' }}>
        <Typography
          sx={{
            color: COLOR_GRAY_BLUE,
            fontSize: '16px',
            padding: '10px 12px',
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }}
        >
          Summary
        </Typography>
        <Box sx={{ padding: '10px 12px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {summaryItems.map(({ label, value, icon }) => (
            <Box key={label} display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ fontSize: '14px', color: COLOR_GRAY_BLUE }}>{label}</Typography>
              <Typography
                sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {icon && <img src={icon} alt={`${label} icon`} width={20} height={20} />}
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            padding: '10px 12px',
            borderTop: '1px solid rgba(145, 158, 171, 0.24)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '16px'
          }}
        >
          <Typography sx={{ color: COLOR_GRAY_BLUE }}>Total Amount</Typography>
          <Typography sx={{ color: 'rgb(51, 102, 255)' }}>
            {formatNumberWithCommas(totalAmount)} USD
          </Typography>
        </Box>
      </Box>

      {/* ------------------- Recipient Details Section ------------------- */}
      <Box pt={2}>
        <Box
          sx={{
            backgroundColor: COLOR_YELLOW,
            p: 2
          }}
        >
          <Typography variant="body1" fontWeight={600}>
            Recipient details:
          </Typography>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ px: 0, py: 0.5 }}>
              <ListItemText
                primary="- &nbsp; Email Address:"
                secondary="ceo@drebest.com"
                primaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', mr: 1, fontSize: '14px' }
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', color: 'blue', fontWeight: 'bold', fontSize: '18px' }
                }}
              />
            </ListItem>
            <ListItem sx={{ px: 0, py: 0.5 }}>
              <ListItemText
                primary="- &nbsp; Amount:"
                secondary={`${formatNumberWithCommas(totalAmount)} USD`}
                primaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', mr: 1, fontSize: '14px' }
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', fontWeight: 'bold', color: 'black' }
                }}
              />
            </ListItem>
            <ListItem sx={{ px: 0, py: 0.5 }}>
              <ListItemText
                primary="- &nbsp; Purpose of this payment:"
                secondary={content ?? ''}
                primaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', mr: 1, fontSize: '14px' }
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  sx: { display: 'inline', fontWeight: 'bold', color: 'black' }
                }}
              />
              <CopyableValue value={content ?? "--"} />
            </ListItem>
          </List>
          <Box mt={1}>
            <Typography variant="body2">
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                Note:{' '}
              </Box>
              After we receive your payment confirmation, please allow up to 1-2 hours for us to
              verify the transaction and credit your balance. We will notify you once the process is
              complete. For assistance, contact us at:{' '}
              <Link href="mailto:support@spendsds.com" color="secondary">
                support@spendsds.com
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(BalanceSummaryMail);
