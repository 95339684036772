import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { BG_COLOR_GREEN, BG_GREEN, modeScreen, PaymentMethod } from '../../_constants_';
import { clearData } from '../../_actions_';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { getListTeamSummary } from '../../_actions_/team.actions';
import AddIcon from '@mui/icons-material/Add';
import { TypographyStyle } from './overview';
import { formatNumberWithCommas } from '../../_helpers_';
import BalanceForm from '../balance/BalanceForm';
import { formAllFields } from '../../pages/Customer/Overview/constant';
import { request } from '../../_services_';
import { DrawerForm } from '../DrawerForm';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export const TeamSummaryOwner = (props) => {
  const { registerNewTeam, team_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const listTeamSummary = useSelector((state) => state?.team?.listTeamSummary);
  const [queryObject, setQueryObject] = useState({});
  const location = useLocation();
  const [modalFormBalance, setModalFormBalance] = useState({
    paymentMethod: PaymentMethod.paypal,
    showTopUpModal: false,
    choosePayment: false,
    current_team: team_id
  });
  const [drawDetail, setDrawDetail] = useState({
    mode: modeScreen.view,
    open: false,
    objectId: null
  });

  const handleOnClose = useCallback(() => {
    setModalFormBalance((prevState) => ({
      ...prevState,
      showTopUpModal: false
    }));
  }, []);

  useEffect(() => {
    if (!listTeamSummary) {
      dispatch(getListTeamSummary(queryObject));
    }
  }, [listTeamSummary]);

  useEffect(() => {
    if (listTeamSummary) dispatch(clearData('listTeamSummary'));
  }, [location]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '0 24px'
        }}
      >
        <Typography variant="h3" gutterBottom>
          Overview
        </Typography>
        <Box display={'flex'} justifyContent="flex-start" gap={3} flexWrap={'wrap'}>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Month'Spent</TypographyStyle>
            <Box>
              <Typography variant="h6" color={'green'} textAlign={'center'}>
                $ {formatNumberWithCommas(listTeamSummary?.overview?.spent_sum)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Credit</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              $ {formatNumberWithCommas(listTeamSummary?.overview?.free_credit_sum)}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Balance</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              $ {formatNumberWithCommas(listTeamSummary?.overview?.total_fund_sum)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '0 24px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            fontSize={24}
            style={{
              fontWeight: 'bold',
              fontSize: '24px',
              marginBottom: '10px'
            }}
          >
            Team summary
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={registerNewTeam}
              size="small"
            >
              Register new team
            </Button>
          </Box>
        </Box>
        <Box className="card-container" sx={{ marginBottom: '16px' }}>
          <Box
            display="flex"
            alignItems="center"
            p={1}
            sx={{
              borderRadius: '8px',
              overflow: 'hidden',
              justifyContent: 'space-between'
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={0}
              sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                width: '30%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    height: '45px',
                    backgroundColor: '#fff'
                  },
                  width: '100%',
                  borderRight: 'none'
                }}
                value={queryObject.search}
                onChange={(event) => {
                  setQueryObject((prev) => ({
                    ...prev,
                    search: event.target.value
                  }));
                }}
              />
              <Button
                variant="contained"
                sx={{
                  height: '45px',
                  backgroundColor: BG_COLOR_GREEN,
                  color: '#fff',
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  '&:hover': {
                    backgroundColor: '#007b55'
                  }
                }}
                onClick={() => dispatch(getListTeamSummary(queryObject))}
              >
                Search
              </Button>
            </Box>
          </Box>
          <Paper
            className={classes.root}
            style={{ position: 'relative', borderTop: '1px solid #ccc' }}
          >
            <TableContainer className={classes.table} style={{ maxHeight: '25vh' }}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Team ID'} arrow placement="top">
                        <Box className="line-clamp-1">Team ID</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Number of account'} arrow placement="top">
                        <Box className="line-clamp-1">Number of account</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '14rem' }}
                    >
                      <Tooltip title={"Month'Spent"} arrow placement="top">
                        <Box className="line-clamp-1">Month'Spent</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Your Credit'} arrow placement="top">
                        <Box className="line-clamp-1">Your Credit</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Your Balance'} arrow placement="top">
                        <Box className="line-clamp-1">Your Balance</Box>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Action'} arrow placement="top">
                        <Box className="line-clamp-1">Action</Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listTeamSummary?.items.map((item) => (
                    <TableRow key={item.team_id} onClick={() => {
                      setDrawDetail({
                        open: true,
                        mode: modeScreen.view,
                        objectId: item.team_id
                      })
                    }}>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>{`Team_${item.team_id}`}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>{item.ads_acc_count ?? '--'}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>
                                  {formatNumberWithCommas(item.total_cost_usd)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>
                                  {formatNumberWithCommas(item.free_credit)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box className={classes.text_ellipsis}>
                                {formatNumberWithCommas(item.total_fund)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box className={classes.text_ellipsis}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<AddIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModalFormBalance((prevState) => ({
                                      ...prevState,
                                      paymentMethod: PaymentMethod.paypal,
                                      showTopUpModal: true,
                                      choosePayment: false,
                                      current_team: item.team_id
                                    }));
                                  }}
                                >
                                  Add balance
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      <DrawerForm
        fields={formAllFields}
        mode={drawDetail.mode}
        open={drawDetail.open}
        onClose={() => {
          setDrawDetail({
            open: false,
            mode: modeScreen.view,
            objectId: null,
          })
        }}
        objectId={drawDetail.objectId}
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/team_summary/${id}`).then(
              (res) => {
                if (res.data.success) {
                  resolve(res.data.data);
                } else {
                  resolve(false);
                }
              },
              (err) => {
                resolve(false);
              }
            );
          });
        }}
        title="Team Summary Detail"
      />

      {modalFormBalance.current_team && (
        <BalanceForm
          dataFormSetUp={modalFormBalance}
          handleOnCloseCallBack={handleOnClose}
          teamId={modalFormBalance.current_team}
        />
      )}
    </>
  );
};
