import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import Select from '@mui/material/Select';
import { AD_FILTER, COLOR_WHITE, PAGINATE_ARR, PER_PAGE, STATUS_ENABLED_ACTION } from '../../_constants_';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomCircularProgress } from '../index';
import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getListRequest } from '../../_actions_';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { formatNumberWithCommas, getButtonColor, isManager } from '../../_helpers_';
import ModalAdFund from './modal-add-fund';
import ModalWithdraw from './modal-withdraw';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function ListAdAccount() {
  const [actionModal, setActionModal] = useState({ add_fund: false, withdraw: false });
  const [selectedAccount, setSelectedAccount] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const listRequests = useSelector((state) => state?.adAccount?.listRequests);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state?.authentication?.user);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team_id: user?.team_id?.id,
    status: AD_FILTER[0].value
  });

  useEffect(() => {
    if (!listRequests) {
      setIsLoading(true);
      dispatch(getListRequest(queryObject));
    }
  }, []);

  useEffect(() => {
    if (listRequests) dispatch(getListRequest(queryObject));
  }, [queryObject]);

  useEffect(() => {
    if (listRequests) setIsLoading(false);
  }, [listRequests]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
  };

  const onChangeActionModal = useCallback((key, value) => {
    setActionModal((prev) => ({
      ...prev,
      [key]: value
    }));
    setSelectedAccount(null);
  }, []);

  return (
    <Box className="card-container" sx={{ marginBottom: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          borderBottom: '1px solid #ccc'
        }}
        p={2}
      >
        <FormControl sx={{ minWidth: 150 }} size="small">
          <Select
            value={queryObject.status}
            onChange={(event) => {
              setQueryObject((prevState) => ({
                ...prevState,
                status: event.target.value
              }));
              dispatch(getListRequest({...queryObject, page: 1 , status: event.target.value }));
            }}
            variant="outlined"
            sx={{ minWidth: 250 }}
          >
            {AD_FILTER.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Paper className={classes.root} style={{ position: 'relative' }}>
        <TableContainer className={classes.table}>
          <Table
            stickyHeader
            className={clsx(
              classes.dynamic_col,
              classes.dynamic_sticky,
              classes.table,
              'dynamic_col'
            )}
          >
            <TableHead className={classes.head_white}>
              <TableRow>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Ad account'} arrow placement="top">
                    <Box className="line-clamp-1">Ad account</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '12rem' }}
                >
                  <Tooltip title={'Business Account'} arrow placement="top">
                    <Box className="line-clamp-1">Business Account</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '14rem' }}
                >
                  <Tooltip title={'Ad account status'} arrow placement="top">
                    <Box className="line-clamp-1">Ad account status</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Time zone'} arrow placement="top">
                    <Box className="line-clamp-1">Time zone</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Cost'} arrow placement="top">
                    <Box className="line-clamp-1">Cost</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Balance'} arrow placement="top">
                    <Box className="line-clamp-1">Balance</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '10rem' }}
                >
                  <Tooltip title={'Action'} arrow placement="top">
                    <Box className="line-clamp-1">Action</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Free credits'} arrow placement="top">
                    <Box className="line-clamp-1">Free credits</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.table_cell,
                    classes.cell_entry,
                    classes.cell_entry_floating,
                    classes.head_cell
                  )}
                  style={{ width: '8rem' }}
                >
                  <Tooltip title={'Discounts'} arrow placement="top">
                    <Box className="line-clamp-1">Discounts</Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listRequests &&
                listRequests.items &&
                listRequests.items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box display={'flex'} gap={1}>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              justifyContent={'center'}
                            >
                              <Typography fontSize={14}>{item.name}</Typography>

                              <Typography fontSize={14}>ID: {item.advertiser_id}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box display={'flex'} gap={1}>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              justifyContent={'center'}
                            >
                              <Typography fontSize={14}>{item.bc_name}</Typography>
                              <Typography fontSize={14}>ID: {item.customer_bc_id}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Button
                          sx={{
                            cursor: 'unset !important',
                            borderRadius: '12px',
                            fontSize: 13,
                            color: COLOR_WHITE,
                            borderColor: COLOR_WHITE,
                            width: '150px',
                            textTransform: 'none',
                            backgroundColor: `${getButtonColor(item.status_display)}!important`,
                            overflow: 'hidden'
                          }}
                          variant="outlined"
                        >
                          <Tooltip title={item.status} placement="top">
                            <Box className={classes.content_inline}>
                              <Box className="text_2">
                                <Box className={classes.text_ellipsis}>{item.status_display}</Box>
                              </Box>
                            </Box>
                          </Tooltip>
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}> {item.timezone}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}>{item.cost}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}>
                              {formatNumberWithCommas(item.balance, true)}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      {item.status === STATUS_ENABLED_ACTION && (
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          {isManager(user?.role) && (
                            <Box className={classes.content_inline}>
                              <Box display={'flex'} gap={0.5} flexDirection={'column'} p={1}>
                                <Box>
                                  <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    size="small"
                                    onClick={() => {
                                      onChangeActionModal('add_fund', true);
                                      setSelectedAccount(item);
                                    }}
                                  >
                                    Add Fund
                                  </Button>
                                </Box>
                                <Box>
                                  <Button
                                    variant="outlined"
                                    startIcon={<RemoveIcon />}
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                      onChangeActionModal('withdraw', true);
                                      setSelectedAccount(item);
                                    }}
                                  >
                                    Withdraw
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}>{item.free_credits}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={clsx(classes.cell_entry)}>
                      <Box
                        className="fnu-view"
                        style={{ position: 'relative', overflow: 'hidden' }}
                      >
                        <Box className={classes.content_inline}>
                          <Box className="text_2">
                            <Box className={classes.text_ellipsis}>{item.discount}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(255,255,255,0.8)'
            }}
          >
            <Box className="p-3 d-flex align-items-center justify-content-center">
              <CustomCircularProgress />
            </Box>
          </Box>
        )}
      </Paper>
      {listRequests && (
        <TablePagination
          className="list-pagination"
          style={{
            borderTop: '1px solid #d8d8d8',
            display: 'flex',
            alignItems: 'center'
          }}
          component="div"
          count={listRequests?.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={queryObject.page - 1}
          rowsPerPage={queryObject.per_page}
          rowsPerPageOptions={PAGINATE_ARR}
        />
      )}
      {selectedAccount && (
        <>
          {actionModal.add_fund && (
            <ModalAdFund
              onClose={() => onChangeActionModal('add_fund', false)}
              account={selectedAccount}
            />
          )}
          {actionModal.withdraw && (
            <ModalWithdraw
              onClose={() => onChangeActionModal('withdraw', false)}
              account={selectedAccount}
            />
          )}
        </>
      )}
    </Box>
  );
}
