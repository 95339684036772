import React, { Component } from 'react';
import {
  TextField, Box, Grid, Button, Paper,
  Checkbox, FormControlLabel, Stack, Typography, Drawer
} from '@mui/material';
import { IconX } from '@tabler/icons';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { customStyles, disabledStyles } from '../utils/selectStyle';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { checkPermissionByRoleEdit, isManager, toastr } from 'src/_helpers_';
import { Scrollbar, CustomNumberFormat, CustomCircularProgress, ModalForm } from '../components';
import { loadOptions, handleInputChange } from '../utils/select';
import DatePicker from "react-datepicker";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { modeScreen, ROLE } from '../_constants_';

const useStyles = theme => ({
  root: {
    width: '100%',
    borderRadius: 0,
    height: '100%'
  },
  main: {
    backgroundColor: '#FFF',
    padding: '0px 20px 20px',
    overflow: 'auto',
  },
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    gap: 10
  },
})

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})(TextField);

class DrawerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        values: {},
        changes: {},
        errors: {},
        isLoading: false,
        isValid: true
      },
      loadingData: false,
    }
  }

  getData = (id, props) => {
    if (props.onLoadData && props.open) {
      this.setState({ loadingData: true }, () => {
        props.onLoadData(id).then(res => {
          const formState = Object.assign({}, this.state.formState)
          formState.values = res?.result || {}
          this.setState({ formState, loadingData: false })
        }, err => {
          this.setState({ loadingData: false })
          toastr.error("There was an error loading your data")
        })
      })
    }
  }

  componentDidMount() {
    this.getData(this.props.objectId, this.props)
  }

  componentWillUpdate(nextProps) {
    if (this.props.objectId != nextProps.objectId) {
      this.getData(nextProps.objectId, nextProps)
    }
  }

  getRibbon = () => {
    if (this.props.ribbon && typeof this.props.ribbon == 'function') {
      return this.props.ribbon(this.state.formState.values)
    }
    return this.props.ribbon
  }

  handleChange = (event) => {
    const formState = Object.assign({}, this.state.formState)
    var value = event.target.value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'radio') {
      value = Number.isNaN(event.target.value) ? event.target.value : Number.parseInt(event.target.value)
    }
    formState.values[event.target.name] = value
    formState.changes[event.target.name] = value
    this.setState({ formState })
  }

  handleChangeStatus = (event) => {
    const formState = Object.assign({}, this.state.formState)
    const value = event.target.value?.value;
    const key = event.target.name;
    formState.values[key] = value
    formState.changes[key] = value
    this.setState({ formState })
  }

  getFieldValue = (field) => {
    if (field.renderValue) {
      return field.renderValue(this.state.formState.values, this)
    }
    return this.state.formState.values[field.name]
  }

  handleCancel = () => {
    this.getData(this.props.objectId, this.props)
  }

  getVisibleAction = (actions) => {
    if (!actions) {
      return []
    }
    const visibleActions = []
    actions.map(action => {
      if (Object.keys(action).includes('visible')) {
        if (typeof action.visible === 'function') {
          if (action.visible(this.state.formState.values)) {
            visibleActions.push(action)
          }
        } else {
          if (action.visible) {
            visibleActions.push(action)
          }
        }
      } else {
        visibleActions.push(action)
      }
    })
    return visibleActions
  }

  getVisibleField = (fields) => {
    if (!fields) {
      return []
    }
    const visibleFields = []
    fields.map(field => {
      if (Object.keys(field).includes('visible')) {
        if (typeof field.visible === 'function') {
          if (field.visible(this.state.formState.values)) {
            visibleFields.push(field)
          }
        } else {
          if (field.visible) {
            visibleFields.push(field)
          }
        }
      } else {
        visibleFields.push(field)
      }
    })
    return visibleFields
  }

  render() {
    const { formState } = this.state;
    const { classes, mode, open, anchor, onClose, width } = this.props;
    const roleUserDetail = formState?.values?.role;

    return (
      <Drawer
        sx={{
          width: width || '35%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: width || '35%',
            boxSizing: 'border-box',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
          },
        }}
        variant="persistent"
        anchor={anchor || 'right'}
        open={open}
      >
        <ModalForm />
        <Stack height={69} paddingX={2.5} direction="row" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid #CCC' }}>
          <Typography variant="h4">
            {this.props.title || 'Detail'}
          </Typography>
          <IconX 
            cursor={"pointer"}
            width={28}
            height={28}
            strokeWidth={2}
            stroke={"#000"}
            onClick={onClose}
          />
        </Stack>
        <Scrollbar>
          {this.state.loadingData ?
            <div className={clsx(classes.root, 'd-flex align-items-center justify-content-center')}>
              <CustomCircularProgress />
            </div>
            :
            <Paper className={classes.root} style={this.props.ribbon && this.getRibbon() ? { ['--bgRibbon']: this.getRibbon().bgColor, ['--textRibbon']: this.getRibbon().textColor } : {}}>
              {this.props.ribbon && this.getRibbon() && <h4 className='ribbon'>{this.getRibbon().text}</h4>}
              {this.props.actions && checkPermissionByRoleEdit(this.props.user?.role, roleUserDetail) &&
                <div className={classes.actions}>
                  {this.getVisibleAction(this.props.actions).map((action, index) => (
                    <Button
                      key={index}
                      color={action.color || 'primary'}
                      size={action.size || 'small'}
                      variant={action.variant || 'contained'}
                      startIcon={action.startIcon}
                      endIcon={action.endIcon}
                      onClick={() => action.action(this, formState)}
                    >
                      {action.text}
                    </Button>
                  ))}
                </div>
              }
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} className={classes.main}>
                  {this.getVisibleField(this.props.fields).filter(e => (e.mode?.includes(mode) || !e.mode)).map(field => {
                    if (field.type === 'text') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <CustomTextField
                            disabled={mode == 'view' || field.disabled}
                            margin="dense"
                            InputProps={{
                              classes: {
                                disabled: classes.input
                              },
                            }}
                            type={'text'}
                            placeholder={field.placeholder}
                            name={field.name}
                            value={this.getFieldValue(field) || ''}
                            onChange={(e) => {
                              if (field.onChange) {
                                field.onChange(e, this)
                              } else {
                                this.handleChange(e)
                              }
                            }}
                            fullWidth
                            variant="outlined"
                            multiline={field.multiline}
                            rows={field.rows || 3}
                          />
                        </div>
                      )
                    } else if (field.type === 'number') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <CustomTextField
                            disabled={mode == 'view' || field.disabled}
                            margin="dense"
                            InputProps={{
                              classes: {
                                disabled: classes.input
                              },
                              inputComponent: CustomNumberFormat
                            }}
                            placeholder={field.placeholder}
                            name={field.name}
                            value={this.getFieldValue(field) || 0}
                            onChange={(e) => {
                              if (field.onChange) {
                                field.onChange(e, this)
                              } else {
                                this.handleChange(e)
                              }
                            }}
                            fullWidth
                            variant="outlined"
                          />
                        </div>
                      )
                    } else if (field.type === 'select') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <AsyncSelect
                            cacheOptions
                            isDisabled={mode == 'view' || field.disabled}
                            loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                            defaultOptions
                            onInputChange={handleInputChange}
                            isSearchable
                            isClearable
                            menuPortalTarget={document.body}
                            name={field.name}
                            onChange={(value) => {
                              var e = {
                                target: {
                                  name: field.name,
                                  value
                                },
                              };
                              if (field.onChange) {
                                field.onChange(e, this)
                              } else {
                                this.handleChange(e)
                              }
                            }}
                            placeholder={field.placeholder || ''}
                            getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                            getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                            valueKey={field.valueKey || "id"}
                            value={this.getFieldValue(field)}
                            styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                          />
                        </div>
                      )
                    } else if (field.type === 'select_create') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <AsyncCreatableSelect
                            cacheOptions
                            isDisabled={mode == 'view' || field.disabled}
                            loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                            defaultOptions
                            onInputChange={handleInputChange}
                            isSearchable
                            isClearable
                            name={field.name}
                            onChange={(value) => {
                              var e = {
                                target: {
                                  name: field.name,
                                  value
                                },
                              };
                              if (field.onChange) {
                                field.onChange(e, this)
                              } else {
                                this.handleChange(e)
                              }
                            }}
                            placeholder={field.placeholder || ''}
                            getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                            getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                            valueKey={field.valueKey || "id"}
                            value={this.getFieldValue(field)}
                            styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                            onCreateOption={(inputValue) => field.onCreateOption(inputValue, this)}
                            getNewOptionData={(inputValue, optionLabel) => ({
                              id: inputValue,
                              name: 'Create \"' + inputValue + '\"...',
                              __isNew__: true
                            })}
                            menuPortalTarget={document.body}
                          />
                        </div>
                      )
                    } else if (field.type === 'multiselect') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isDisabled={mode == 'view' || field.disabled}
                            loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                            defaultOptions
                            onInputChange={handleInputChange}
                            isSearchable
                            isClearable
                            name={field.name}
                            onChange={(value) => {
                              var e = {
                                target: {
                                  name: field.name,
                                  value
                                },
                              };
                              if (field.onChange) {
                                field.onChange(e, this)
                              } else {
                                this.handleChange(e)
                              }
                            }}
                            placeholder={field.placeholder || ''}
                            getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                            getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                            valueKey={field.valueKey || "id"}
                            value={this.getFieldValue(field) || []}
                            styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                          />
                        </div>
                      )
                    }
                    else if (field.type === 'selectOptions') {
                      return (
                        <Box className="mb-2 mt-2" key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          {mode === modeScreen.view ? (
                            <CustomTextField
                              fullWidth
                              disabled
                              value={formState.values[field.name]}
                              variant="outlined"
                            />
                          ) : (
                            <AsyncSelect
                              defaultOptions={field.options}
                              isSearchable
                              isClearable
                              name={field.name}
                              onChange={(value) => {
                                const event = {
                                  target: {
                                    name: field.name,
                                    value
                                  },
                                };
                                this.handleChangeStatus(event)
                              }}
                              placeholder={field.placeholder || ''}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              value={this.getFieldValue(field)}
                              className={'font-14'}
                              styles={field.disabledFunc(formState.values, this) ? disabledStyles : customStyles}
                              isDisabled={field.disabledFunc(formState.values, this)}
                            />
                          )}
                        </Box>
                      )
                    } else if (field.type === 'datetime') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <DatePicker
                            className='mt-1 font-date'
                            disabled={mode == 'view' || field.disabled}
                            wrapperClassName='custom-picker'
                            popperClassName='custom-popper-picker'
                            selected={this.getFieldValue(field) || null}
                            onChange={(value) => {
                              const event = {
                                target: {
                                  name: field.name,
                                  value
                                }
                              }
                              if (field.onChange) {
                                field.onChange(event, this)
                              } else {
                                this.handleChange(event)
                              }
                            }}
                            timeInputLabel="Time:"
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            showTimeInput
                          />
                        </div>
                      )

                    } else if (field.type === 'date') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <DatePicker
                            className='mt-1 font-date'
                            disabled={mode == 'view' || field.disabled}
                            wrapperClassName='custom-picker'
                            popperClassName='custom-popper-picker'
                            selected={this.getFieldValue(field) || null}
                            onChange={(value) => {
                              const event = {
                                target: {
                                  name: field.name,
                                  value
                                }
                              }
                              if (field.onChange) {
                                field.onChange(event, this)
                              } else {
                                this.handleChange(event)
                              }
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      )

                    } else if (field.type === 'checkbox') {
                      return (
                        <div className='mt-2' key={field.name}>
                          <Box fontSize={15} fontWeight="bold">
                            {field.label}
                          </Box>
                          <FormControlLabel
                            label={""}
                            control={
                              <Checkbox
                                checked={field.checked(formState.values)}
                                onChange={event => {
                                  if (field.onChange) {
                                    field.onChange(event, this)
                                  } else {
                                    this.handleChange(event)
                                  }
                                }}
                                color="primary"
                                disabled={mode == 'view' || field.disabled}
                                name={field.name}
                              />
                            }
                          />
                        </div>
                      )
                    } else if (field.type === 'custom') {
                      return (
                        <div className='mt-2' key={field.name}>
                          {field.renderValue(formState.values, this)}
                        </div>
                      )
                    }
                  })}
                </Grid>
              </Grid>
            </Paper>
          }
        </Scrollbar>
      </Drawer>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedForm = connect(mapStateToProps)(withStyles(useStyles)(DrawerForm));
export { connectedForm as DrawerForm }