import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';

export const getListTeamSummary = (query) => async (dispatch) => {
  try {
    const res = await request.get('/api/v1/admin_team_summary_v2', query);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
          type: t.LIST_TEAM_SUMMARY,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const getTeamSummary = (id) => async (dispatch) => {
  try {
    const res = await request.get(`/api/v1/admin_team_summary_v2/${id}`);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.TEAM_SUMMARY,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};
