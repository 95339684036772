import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material';
import { DrawerForm, ModalForm } from '../../../../components';
import { formatNumberWithCommas, toastr } from 'src/_helpers_';
import { request } from '../../../../_services_';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../../utils';
import { IconTrash } from '@tabler/icons';
import { validate } from 'validate.js';
import { formFields } from '../constant';
import { TypographyStyle } from '../../../../components/overview/overview';
import OverviewMyAdsAccount from 'src/components/overview/overviewMyAdsAccount';
import { BG_GREEN, PaymentMethod, ROLE } from '../../../../_constants_';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../../../_constants_/share-style';
import { getTeamSummary } from '../../../../_actions_/team.actions';
import BalanceForm from '../../../../components/balance/BalanceForm';
import { useLocation } from 'react-router-dom';
import { clearData } from '../../../../_actions_';

const roles = [
  { value: 'teamlead', label: 'Teamlead' },
  { value: 'seller', label: 'Seller' }
];

const usersSchema = {
  email: {
    presence: { message: '^Required' },
    email: {
      message: '^Your email is invalid'
    }
  }
};
const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

const Overview = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [objectId, setObjectId] = useState(null);
  const isAdmin = user?.admin ?? false;
  const classes = useStyles();
  const teamSummary = useSelector((state) => state?.team?.teamSummary);
  const location = useLocation();
  const [modalFormBalance, setModalFormBalance] = useState({
    paymentMethod: PaymentMethod.paypal,
    showTopUpModal: false,
    choosePayment: false
  });
  const handleOnClose = useCallback(() => {
    setModalFormBalance((prevState) => ({
      ...prevState,
      showTopUpModal: false
    }));
  }, []);

  useEffect(() => {
    if (!teamSummary && user?.team_id?.id) {
      dispatch(getTeamSummary(user?.team_id?.id));
    }
  }, [teamSummary]);

  useEffect(() => {
    if (teamSummary) dispatch(clearData('teamSummary'));
  }, [location]);

  const registerNewTeam = () => {
    ModalForm.instance.current.openForm({
      title: 'Register New Team',
      data: {
        users: [
          {
            email: '',
            role: ROLE.TEAMLEAD
          }
        ]
      },
      customView: (submitData, handleChange) => (
        <div className="p-2">
          <div className="mt-2">
            <Box fontSize={15} fontWeight="bold">
              Platform
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) =>
                loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise((resolve) => {
                    request.get('/api/v1/team_platforms', { name: inputValue }).then((res) => {
                      if (res.data.success) {
                        resolve(res.data.data.items);
                      }
                    });
                  });
                })
              }
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="platform_id"
              onChange={(value) => {
                handleChange({
                  target: {
                    name: 'platform_id',
                    value
                  }
                });
              }}
              placeholder={'Platform...'}
              menuPortalTarget={document.body}
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              valueKey={'platform_id'}
              value={submitData?.values.platform_id || null}
              styles={submitData.errors?.platform_id ? errorStyles : customStyles}
            />
            {submitData.errors?.platform_id && (
              <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>
                {submitData.errors.platform_id[0]}
              </span>
            )}
          </div>

          {/* Users Section */}
          <div className="mt-2">
            <Box fontSize={15} fontWeight="bold">
              User
            </Box>
            {submitData.values.users?.map((item, index) => (
              <Grid key={index} container spacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="email"
                    onChange={(e) => {
                      const updatedUsers = [...submitData.values.users];
                      updatedUsers[index] = {
                        ...item,
                        email: e.target.value
                      };
                      const errors = validate({ email: e.target.value }, usersSchema);
                      updatedUsers[index].isValid = !errors;
                      updatedUsers[index].errors = errors || {};

                      handleChange({
                        target: {
                          name: 'users',
                          value: updatedUsers
                        }
                      });
                    }}
                    value={item?.email}
                    variant="outlined"
                    placeholder="Email"
                    type="email"
                    error={item.errors?.email}
                    helperText={item.errors?.email}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AsyncSelect
                    className="MuiFormControl-marginDense"
                    defaultOptions={roles}
                    value={item?.role}
                    onChange={(value) => {
                      const updatedUsers = [...submitData.values.users];
                      updatedUsers[index] = {
                        ...item,
                        role: value
                      };
                      handleChange({
                        target: {
                          name: 'users',
                          value: updatedUsers
                        }
                      });
                    }}
                    placeholder="User role"
                    styles={customStyles}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      const updatedUsers = submitData.values.users.filter((_, i) => i !== index);
                      handleChange({
                        target: {
                          name: 'users',
                          value: updatedUsers
                        }
                      });
                    }}
                  >
                    <IconTrash color="red" width={16} height={16} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              onClick={() => {
                const users = [...submitData.values.users];
                users.push({
                  email: '',
                  role: roles[0]
                });
                handleChange({
                  target: {
                    name: 'users',
                    value: users
                  }
                });
              }}
            >
              Add
            </Button>
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          const schema = {
            platform_id: { presence: { message: '^Required' } }
          };
          if (submitData.values.platform?.value === 'tiktok') {
            schema['product_url'] = {
              presence: { message: '^Required' }
            };
          }
          return schema;
        },
        onAction: async (submitData) => {
          const query = { ...submitData.values };
          if (submitData.values.platform_id) {
            query.platform_id = submitData.values.platform_id.id;
          }

          query.users = query.users
            .filter((user) => user.email !== '')
            .map((user) => ({
              email: user.email,
              role: user.role.value
            }));

          try {
            const response = await request.post('/api/v1/quick_create_team', query);
            if (response.data.success) {
              toastr.success('Create team successful');
              const dataTeam = response.data.data.list_team;
              dispatch({ type: 'LOAD_LIST_TEAM', dataTeam });
              return true;
            } else {
              toastr.error(response.data.msg);
              return false;
            }
          } catch (err) {
            toastr.error(err);
            return false;
          }
        }
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={isAdmin ? 2.2 : 3}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '0 24px',
          mb: 5
        }}
      >
        <Typography variant="h3" gutterBottom>
          Overview
        </Typography>
        <Box display={'flex'} justifyContent="flex-start" gap={3} flexWrap={'wrap'}>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Month'Spent</TypographyStyle>
            <Box>
              <Typography variant="h6" color={'green'} textAlign={'center'}>
                $ {formatNumberWithCommas(teamSummary?.overview?.spent_sum)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Credit</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              $ {formatNumberWithCommas(teamSummary?.overview?.free_credit_sum)}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Balance</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              $ {formatNumberWithCommas(teamSummary?.overview?.total_fund_sum)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ padding: '0 24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">Team Summary</Typography>
          {(user?.is_owner || user?.group?.some((e) => e.code === ROLE.TEAMLEAD)) && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={registerNewTeam}
              size="small"
            >
              Register new team
            </Button>
          )}
        </Box>

        <Box className="card-container" sx={{ marginBottom: '16px' }}>
          <Paper className={classes.root} style={{ position: 'relative', borderRadius: '6px' }}>
            <TableContainer className={classes.table} style={{ maxHeight: '25vh' }}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Team ID'} arrow placement="top">
                        <Box className="line-clamp-1">Team ID</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Number of account'} arrow placement="top">
                        <Box className="line-clamp-1">Number of account</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '14rem' }}
                    >
                      <Tooltip title={"Month'Spent"} arrow placement="top">
                        <Box className="line-clamp-1">Month'Spent</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Your Credit'} arrow placement="top">
                        <Box className="line-clamp-1">Your Credit</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '12rem' }}
                    >
                      <Tooltip title={'Your Balance'} arrow placement="top">
                        <Box className="line-clamp-1">Your Balance</Box>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Action'} arrow placement="top">
                        <Box className="line-clamp-1">Action</Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {teamSummary?.items.map((item) => (
                    <TableRow
                      key={item.team_id}
                      onClick={() => {
                        setOpen(true);
                        setMode('view');
                        setObjectId(item.team_id);
                      }}
                    >
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>{`Team_${item.team_id}`}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>{item.ads_acc_count ?? '--'}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>
                                  {formatNumberWithCommas(item.total_cost_usd)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box display={'flex'} gap={1}>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                              >
                                <Typography fontSize={14}>
                                  {formatNumberWithCommas(item.free_credit)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box className={classes.text_ellipsis}>
                                {formatNumberWithCommas(item.total_fund)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box className={classes.text_ellipsis}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<AddIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModalFormBalance((prevState) => ({
                                      ...prevState,
                                      paymentMethod: PaymentMethod.paypal,
                                      showTopUpModal: true,
                                      choosePayment: false,
                                      current_team: item.team_id
                                    }));
                                  }}
                                >
                                  Add balance
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      <BalanceForm dataFormSetUp={modalFormBalance} handleOnCloseCallBack={handleOnClose} />

      <DrawerForm
        fields={formFields}
        mode={mode}
        open={open}
        onClose={() => {
          setOpen(false);
          setMode('view');
          setObjectId(null);
        }}
        objectId={objectId}
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/team_summary/${id}`).then(
              (res) => {
                if (res.data.success) {
                  resolve(res.data.data);
                } else {
                  resolve(false);
                }
              },
              (err) => {
                resolve(false);
              }
            );
          });
        }}
        title="Team Summary Detail"
      />

      {!isAdmin && (
        <Box sx={{ padding: '0 25px 100px 25px' }}>
          <Typography
            fontSize={24}
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px'
            }}
          >
            My ads account
          </Typography>
          <OverviewMyAdsAccount />
        </Box>
      )}
    </Box>
  );
};

export default Overview;
