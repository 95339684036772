import { Icon } from '@iconify/react';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
// import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import GroupIcon from '@mui/icons-material/Group';
import PaidIcon from '@mui/icons-material/Paid';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import FacebookIcon from '@mui/icons-material/Facebook';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WebIcon from '@mui/icons-material/Web';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { IconBox } from '@tabler/icons';
import { IconWallet } from '@tabler/icons';
import { ADS_ACCOUNT, OVERVIEW, PAYMENT, PRODUCT_LINKS } from '../../_constants_';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Overview',
    path: OVERVIEW,
    icon: <SummarizeIcon />
  },
  // {
  //   title: 'Affiliate Money',
  //   path: ROUTE_HOME,
  //   icon: <AnalyticsIcon />
  // },
  // {
  //   title: 'Team summary',
  //   path: '/team_summary',
  //   icon: <SummarizeIcon />
  // },
  {
    title: 'Team user',
    path: '/team_user',
    icon: <GroupIcon />
  },
  // {
  //   title: '2.3 Bank Info',
  //   path: '/user_commissions',
  //   icon: <FormatListBulletedIcon />
  // },
  {
    title: 'Payment',
    path: PAYMENT,
    icon: <IconWallet />
  },
  {
    title: 'My ad accounts',
    path: ADS_ACCOUNT,
    icon: <RecentActorsIcon />
  },
  {
    title: 'Product links',
    path: PRODUCT_LINKS,
    icon: <DatasetLinkedIcon />
  },
  // {
  //   title: 'Total Fund',
  //   path: '/transaction_histories',
  //   icon: <PaidIcon />
  // },
  // {
  //   title: '3.2 FUD',
  //   path: '/fud',
  //   icon: <FontAwesomeIcon icon={faHandshake} />
  // },
  {
    title: 'Services',
    path: '/services',
    icon: <IconBox size={28} stroke={2} />
  }
  // {
  //   title: '3.4 Transaction History',
  //   path: '/transaction_histories',
  //   icon: <IconBox size={28} stroke={2} />
  // },
  // {
  //   title: 'Web - Page Info',
  //   path: '/webpage_info',
  //   icon: <WebIcon />
  // },
  // {
  //   title: 'Seller Account',
  //   path: '/seller_accounts',
  //   icon: <FacebookIcon />
  // },
  // {
  //   title: 'Request Support',
  //   path: '/request_supports',
  //   icon: <SupportAgentIcon />
  // },
  // {
  //   title: 'Payoneer Integration',
  //   path: '/payment_gateway/payoneer',
  //   icon: (
  //     <svg viewBox="0 0 24 24" width="20" height="20">
  //       <path
  //         d="M 24 19.8 C 24 22.125 22.125 24 19.8 24 L 4.2 24 C 1.875 24 0 22.125 0 19.8 L 0 4.2 C 0 1.875 1.875 0 4.2 0 L 19.8 0 C 22.125 0 24 1.875 24 4.2 L 24 19.8 Z"
  //         fill="#ff4800"
  //       ></path>
  //       <path
  //         d="M 5.214 6.3 C 5.274 7.676 6.464 12.93 6.464 12.93 C 6.903 13.994 7.275 12.93 7.275 12.93 C 8.718 8.615 5.773 6.114 5.773 6.114 C 5.027 5.675 5.213 6.3 5.213 6.3 M 22.283 5.549 C 22.283 5.549 23.726 6.553 22.283 6.739 C 22.283 6.739 16.904 7.364 12.901 11.115 C 12.901 11.115 6.902 16.369 4.648 22.121 C 4.648 22.121 3.897 22.121 4.149 20.997 C 4.402 19.872 5.899 14.931 12.589 8.866 C 12.589 8.866 19.032 2.988 22.284 5.549"
  //         fill="#fff"
  //       ></path>
  //     </svg>
  //   )
  // }
];

export default sidebarConfig;
