// material
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Container, Typography, InputAdornment, IconButton, Stack, TextField, Grid, FormControlLabel, Link, Card
} from '@mui/material';
import { Page } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import AuthLayout from '../../../layouts/AuthLayout';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { LoadingButton } from '@mui/lab';
import { request } from '../../../_services_'
import { MHidden } from '../../../components/@material-extend';
import { toastr } from '../../../_helpers_';
import { slugifyStr } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import ModalForm from 'src/components/ModalForm';
import { PRIVACY_POLICY, TERMS_SERVICE } from '../../../_constants_';

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  paddingTop: theme.spacing(4),
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const Button = styled('button')(({theme}) => ({
  textDecoration: 'underline',
  marginLeft: 5
}))
// ----------------------------------------------------------------------

export default function RegisterCompany() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().required('Password is required'),
    // fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Fullname is required'),
    // shortname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
    // .matches(
    //   /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,"Shortname contain only latin"
    // ),
    // facebook_link: Yup.string().matches(
    //   /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/, 'Enter correct facebook link!'
    // ),
    // company_shortname: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Enter a valid shortname").max(4, 'Max 4 characters!'),
    // phone: Yup.string().matches(/^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //  'Phone number is not valid').min(8, 'Phone number is not valid').max(15, 'Phone number is not valid')
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const code = query.get('aff_code')
    if (code) {
      cookie.save('aff_code', code, { domain: process.env.REACT_APP_DOMAIN })
      request.get('/api/v1/affiliate', {aff_code: code})
    }
  }, [])
  
  const pushModal = () => {
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Create company',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15}>
              Congratulations, you have created a successful company. Last step, we have sent a link to your email, 
              please check your mailbox and follow the instructions to login.
            </Box>
          </div>
        </div>
      ),
      actionAfterClose: (ctxClose) => navigate('/login', {replace: true})
    })
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullname: "",
      shortname: "",
      facebook_link: "",
      company_name: "",
      company_shortname: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const query = Object.assign({}, values)
      if (cookie.load('aff_code')) {
        query['aff_code'] = cookie.load('aff_code')
      }
      request.post('/api/v1/register_company', query).then(res => {
        formik.setSubmitting(false)
        if (res.data.success) {
          toastr.success("Create company success")
          pushModal()  
        } else {
          if (res.data.err?.existed) {
            toastr.error(`${res.data.msg}. <a href="/login">Login</a>`)
          } else {
            toastr.error(res.data.msg)
          }
        }
      },error=>{
        formik.setSubmitting(false)
        toastr.error(error?.response?.data?.msg || error.toString())
      })
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;
  return (
    <RootStyle title="SpendAds | Register Company">
      <ModalForm />
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {" "}
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="md">

        <ContentStyle>
          <Grid>
            <div style={{ padding: "10px 5px" }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div style={{
                    // position: '-webkit-sticky',
                    position: 'sticky',
                    top: -24,
                    paddingTop: 24,
                    background: '#fff',
                    zIndex: 10
                  }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                      <Typography variant="h4" gutterBottom>
                        Register new company
                      </Typography>
                      {/* <LoadingButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        loading={isSubmitting}
                        // disabled={!values.agree_term}
                        type="submit"
                      >
                        Save
                      </LoadingButton> */}
                    </Stack>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div>
                        <Box className='mb-1' fontWeight={"bold"}>Email address (Địa chỉ Email) <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          autoComplete="new-email"
                          type="email"
                          placeholder="Email address"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                      {/* <div className='mt-2'>
                        <Box fontWeight={"bold"}>Password <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          autoComplete="new-password"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Password"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </div> */}
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Fullname (Họ và Tên) 
                          {/* <span style={{ color: 'red' }}>*</span> */}
                        </Box>
                        <TextField
                          fullWidth
                          placeholder="Nguyễn Văn A"
                          {...getFieldProps('fullname')}
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                          onChange={e => {
                            setFieldValue('fullname', e.target.value)
                            setFieldValue('shortname', slugifyStr(e.target.value))
                          }}
                        />
                      </div>
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Shortname</Box>
                        <TextField
                          disabled
                          fullWidth
                          placeholder="Shortname"
                          {...getFieldProps('shortname')}
                          error={Boolean(touched.shortname && errors.shortname)}
                          helperText={touched.shortname && errors.shortname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB profile name</Box>
                        <TextField
                          fullWidth
                          placeholder="FB profile name"
                          {...getFieldProps('facebook_profile_name')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB link</Box>
                        <TextField
                          fullWidth
                          placeholder="FB link"
                          {...getFieldProps('facebook_link')}
                          error={Boolean(touched.facebook_link && errors.facebook_link)}
                          helperText={touched.facebook_link && errors.facebook_link}
                        />
                      </div> */}
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Phone Number (Số điện thoại)</Box>
                        <TextField
                          fullWidth
                          placeholder="0987654321"
                          {...getFieldProps('phone')}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}

                        />
                      </div>
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Company fullname</Box>
                        <TextField
                          fullWidth
                          placeholder="Company fullname"
                          {...getFieldProps('company_name')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Company shortname</Box>
                        <TextField
                          fullWidth
                          placeholder="Company shortname"
                          {...getFieldProps('company_shortname')}
                          error={Boolean(touched.company_shortname && errors.company_shortname)}
                          helperText={touched.company_shortname && errors.company_shortname}
                        />
                      </div> */}
                      <div className='mt-2'>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          By register you agree to SpendAds&nbsp;
                          <Link href={TERMS_SERVICE} target={"_blank"} underline="always" sx={{ color: 'text.primary' }}>
                            Terms of Service
                          </Link>
                          &nbsp;and&nbsp;
                          <Link href={PRIVACY_POLICY} target={"_blank"} underline="always" sx={{ color: 'text.primary' }}>
                            Privacy Policy.
                          </Link>
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" my={4}>
                    <LoadingButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      loading={isSubmitting}
                      // disabled={!values.agree_term}
                      type="submit"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Form>
              </FormikProvider>
            </div>
          </Grid>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
