import { Box, Button, Typography } from '@mui/material';
import { BG_GREEN, PaymentMethod } from '../../_constants_';
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import BalanceForm from '../balance/BalanceForm';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getMyWallet, getTiktokFinance } from '../../_actions_';
import { formatNumberWithCommas, getCurrentMonthYear } from '../../_helpers_';
import { useLocation } from 'react-router-dom';

export const TypographyStyle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'center'
});

const OverviewComponent = (props) => {
  const { manager, team_id } = props;
  const dispatch = useDispatch();
  const dataWallet = useSelector((state) => state?.payment?.dataWallet);
  const dataTiktokFinance = useSelector((state) => state?.payment?.dataTiktokFinance);
  const [modalFormBalance, setModalFormBalance] = useState({
    paymentMethod: PaymentMethod.paypal,
    showTopUpModal: false,
    choosePayment: false
  });
  const location = useLocation();

  useEffect(() => {
    if (dataWallet) dispatch(getMyWallet());
    if (dataTiktokFinance) dispatch(clearData('dataAddFundAdvertiser'));
  }, [location]);

  useEffect(() => {
    if (!dataWallet) {
      dispatch(getMyWallet());
    }
    if (!dataTiktokFinance && team_id) {
      dispatch(
        getTiktokFinance(team_id, {
          year_month: getCurrentMonthYear()
        })
      );
    }
  }, []);

  const handleOnClose = useCallback(() => {
    setModalFormBalance((prevState) => ({
      ...prevState,
      showTopUpModal: false
    }));
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '0 24px'
        }}
      >
        <Typography variant="h3" gutterBottom>
          Overview
        </Typography>
        <Box display={'flex'} justifyContent="flex-start" gap={3} flexWrap={'wrap'}>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Month'Spent</TypographyStyle>
            <Box>
              <Typography variant="h6" color={'green'} textAlign={'center'}>
                ${formatNumberWithCommas(dataTiktokFinance?.cost)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Credit</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              $0
            </Typography>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: BG_GREEN, p: 2, borderRadius: 2 }}>
            <TypographyStyle>Your Balance</TypographyStyle>
            <Typography variant="h5" color={'green'} textAlign={'center'}>
              ${formatNumberWithCommas(dataWallet?.total_fund)}
            </Typography>
          </Box>
          {manager && (
            <>
              <Box flex={1} p={2}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setModalFormBalance((prevState) => ({
                      ...prevState,
                      paymentMethod: PaymentMethod.paypal,
                      showTopUpModal: true,
                      choosePayment: false
                    }));
                  }}
                >
                  Add balance
                </Button>
              </Box>
              <BalanceForm dataFormSetUp={modalFormBalance} handleOnCloseCallBack={handleOnClose} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OverviewComponent;
