export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #C1C7CE',
    marginTop: 3,
    padding: '3px 0px',
    backgroundColor: 'transparent',
    borderRadius: 8,
    fontSize: 14
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px'
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: 200,
    zIndex: 9999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    textTransform: 'capitalize'
  })
};

export const errorStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid red',
    marginTop: 6,
    padding: '3px 0px',
    backgroundColor: 'transparent',
    borderRadius: 8,
    fontSize: 14
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px'
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: 200,
    zIndex: 9999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black'
  })
};

export const disabledStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #D8D8D8',
    marginTop: 3,
    padding: '3px 0px',
    backgroundColor: '#ECECEC',
    borderRadius: 8,
    fontSize: 14
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px'
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: 200,
    zIndex: 9999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black'
  })
};