import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Tooltip,
  Button,
  Link
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Scrollbar } from '../index';
import { getRefundHistory } from '../../_actions_';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import {
  formatDateToTimezone,
  formatNumberTo4Character,
  getColorBalance,
  getImageUrl
} from '../../_helpers_';
import { PAGINATE_ARR, PER_PAGE } from '../../_constants_';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

const RefundHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const balanceHistory = useSelector((state) => state?.payment?.balanceHistory);
  const user = useSelector((state) => state?.authentication?.user);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team_id: user?.team_id?.id
  });

  useEffect(() => {
    if (!balanceHistory) {
      dispatch(getRefundHistory(queryObject));
    }
  }, []);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getRefundHistory({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prev) => ({
      ...prev,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getRefundHistory({ ...queryObject, page: 1, per_page: event.target.value }));
  };
  return (
    <Box>
      <Scrollbar>
        <Box style={{ border: '1px solid #ccc', borderRadius: 6 }}>
          <Paper
            className={classes.root}
            style={{ position: 'relative', borderRadius: '6px 6px 0px 0px' }}
          >
            <TableContainer style={{ maxHeight: '50vh' }} className={classes.table}>
              <Table
                stickyHeader
                className={clsx(
                  classes.dynamic_col,
                  classes.dynamic_sticky,
                  classes.table,
                  'dynamic_col'
                )}
              >
                <TableHead className={classes.head_white}>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Refund ID'} arrow placement="top">
                        <Box className="line-clamp-1">Refund ID</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '10rem' }}
                    >
                      <Tooltip title={'Refund Time'} arrow placement="top">
                        <Box className="line-clamp-1">Refund Time</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '9rem' }}
                    >
                      <Tooltip title={'Amount USD'} arrow placement="top">
                        <Box className="line-clamp-1">Amount USD</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '15rem' }}
                    >
                      <Tooltip title={'Refund Note'} arrow placement="top">
                        <Box className="line-clamp-1"> Refund Note</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '13rem' }}
                    >
                      <Tooltip title={'Operator'} arrow placement="top">
                        <Box className="line-clamp-1">Operator</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.table_cell,
                        classes.cell_entry,
                        classes.cell_entry_floating,
                        classes.head_cell
                      )}
                      style={{ width: '7rem' }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceHistory?.items?.map((item) => (
                    <TableRow key={item.id} onClick={() => {}}>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {formatNumberTo4Character(item?.id)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {formatDateToTimezone(item.create_date)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                -
                                {item.amount?.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                  maximumFractionDigits: 2
                                })}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {item.note}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          <Box className={classes.content_inline}>
                            <Box className="text_2">
                              <Box
                                className={classes.text_ellipsis}
                                style={{ color: getColorBalance(item) }}
                              >
                                {item.create_user?.email}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={clsx(classes.cell_entry)}>
                        <Box
                          className="fnu-view"
                          style={{ position: 'relative', overflow: 'hidden' }}
                        >
                          {item?.refund_proof?.image_path && (
                            <Box className={classes.content_inline}>
                              <Box className="text_2">
                                <Box className={classes.text_ellipsis}>
                                  <Button
                                    variant="outlined"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="secondary"
                                  >
                                    <Link
                                      href={getImageUrl(item?.refund_proof?.image_path ?? '')}
                                      underline="hover"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Image
                                    </Link>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {balanceHistory && (
            <TablePagination
              className="list-pagination"
              style={{
                borderTop: '1px solid #D8D8D8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              component="Box"
              count={balanceHistory?.total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={queryObject.page - 1}
              rowsPerPage={queryObject.per_page}
              rowsPerPageOptions={PAGINATE_ARR}
            />
          )}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default RefundHistory;
