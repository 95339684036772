import * as t from '../_constants_/redux';

const initialState = {
  listTeamSummary: null,
  teamSummary: null,
};

export function team(state = initialState, action) {
  switch (action.type) {
    case t.LIST_TEAM_SUMMARY: {
      return {
        ...state,
        listTeamSummary: action.payload
      };
    }
    case t.TEAM_SUMMARY: {
      return {
        ...state,
        teamSummary: action.payload
      };
    }
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        errors: null
      };
    case t.ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.errors
      };
    default:
      return state;
  }
}
