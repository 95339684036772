import {
  AD_ACCOUNT_METHODS,
  adStatus,
  recipientMethods,
  ROLE,
  settingOptions,
  targetMarketOption
} from '../_constants_';

export const isManager = (role) => {
  return role === ROLE.OWNER || role === ROLE.TEAMLEAD;
}

export function formatNumberWithCommas(number, style = false) {
  if (typeof number !== 'number' || isNaN(number)) return '--';
  let options = { currency: 'USD', maximumFractionDigits: 2 };
  if (style) options = { ...options, style: 'currency' };
  const currencyFormatter = new Intl.NumberFormat('en-US', options);
  return currencyFormatter.format(number);
}

export function renderQRCode(data) {
  const accountName = process.env.REACT_APP_BANK_ACCOUNT_NAME ?? '';
  const qrUrl = process.env.REACT_APP_BANK_QR_URL ?? '';
  return `${qrUrl}?amount=${data.amount}&addInfo=${encodeURIComponent(data.content)}&accountName=${encodeURIComponent(accountName)}`;
}

export const getColorBalance = (item) => {
  if (item.old_balance != null && item.new_balance != null) {
    return item.new_balance - item.old_balance > 0 ? 'green' : 'red';
  }
  return 'black';
};

export const getColorRowTransaction = (value) => {
  const adds = ['in', 'RECHARGE'];
  const subs = ['REFUND', 'out'];

  if (adds.includes(value)) {
    return 'green';
  } else if (subs.includes(value)) {
    return 'red';
  } else {
    return 'black';
  }
};

export const getRecipientMethodMessage = (method) => {
  switch (method) {
    case recipientMethods.bank_tranfer:
      return "Please provide all required information: bank name, account holder's name, and account number.";

    case recipientMethods.paypal:
      return 'Please provide the registered email address or phone number linked to your PayPal account.';

    case recipientMethods.payoneer:
      return "Please provide the email address linked to your Payoneer account and the recipient's or company's name, or the account number.";

    case recipientMethods.pingpong:
      return "Please provide the recipient's or company's name along with your PingPong account email address or account number.";

    default:
      return 'Invalid method. Please provide valid payment information.';
  }
};

export const getButtonColor = (statusValue, options = adStatus) => {
  const status = options.find((item) => item.value === statusValue);

  if (!status) return 'purple';

  switch (status.color) {
    case 'warning':
      return '#ffc107';
    case 'success':
      return '#28a745';
    case 'error':
      return '#dc3545';
    default:
      return 'white';
  }
};

export const getLabelById = (id, options = targetMarketOption) => {
  const target = options.find((option) => option.value === id);
  return target ? target.label : null;
};

export const getLabelByCode = (code, statusArray) => {
  const status = statusArray.find((item) => item.value === code);
  return status ? status.label : '';
};

// UTC+7
export const optionsDate = {
  timeZone: 'Asia/Bangkok',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
};

// convert timestamp UTC +7
// exam: input UTC +0 - Fri, 08 Nov 2024 04:50:44 GMT; output UTC +7 - YYYY/MM/DD hh:mm:ss
export const formatDateToTimezone = (inputDate) => {
  if (!inputDate) {
    return '--';
  }

  const date = new Date(inputDate);
  const parts = date.toLocaleString('en-CA', optionsDate).split(', ');
  return `${parts[0].replace(/-/g, '/')} ${parts[1]}`;
};

// convert timestamp UTC +7
// exam: input UTC +0 - 25/11/2024 07:49:53; output UTC +7 - YYYY/MM/DD hh:mm:ss
export const convertDateWithTimezone = (inputDate) => {
  if (!inputDate) {
    return '--';
  }

  const [datePart, timePart] = inputDate.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);

  const dateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  const parts = dateUTC.toLocaleString('en-CA', optionsDate).split(', ');
  return `${parts[0].replace(/-/g, '/')} ${parts[1]}`;
};

export const getImageUrl = (imagePath) => {
  return `${process.env.REACT_APP_API_URL}/images/${imagePath}`;
};

export const formatNumberTo4Character = (num) => {
  return num.toString().padStart(4, '0');
};

export const getCurrentMonthYear = () => {
  const currentDate = new Date();
  return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
}

export const isValidAdAccountMethod = (value) => {
  return AD_ACCOUNT_METHODS.includes(value);
};

export function convertActiveToStatus(active, key = 'value') {
  if (active === true) {
    return settingOptions.find(option => option.value === 'active')[key];
  } else if (active === false) {
    return settingOptions.find(option => option.value === 'inactive')[key];
  } else {
    return key === 'value' ? '--' : '--';
  }
}

export const checkPermissionByRoleEdit = (roleAuth, roleEdit) => {
  // Case1: User has role is owner is edit all
  if (roleAuth === ROLE.OWNER) {
    return true;
  }

  // Case2: User has role is team lead and editing record is not owner
  if (roleAuth === ROLE.TEAMLEAD && roleEdit !== ROLE.OWNER) {
    return true;
  }

  return false;
}
