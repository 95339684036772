export const ROUTE_HOME = '/team_summary';
export const OVERVIEW = '/overview';
export const OVERVIEW_OWNER = '/overview_owner';
export const PAYMENT = '/my_wallet';
export const ADS_ACCOUNT = '/ad_accounts';
export const PRODUCT_LINKS = '/product_links';

// public route
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_SERVICE = '/terms-of-service';
