import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { convertActiveToStatus, getButtonColor } from '../../../_helpers_';
import { COLOR_WHITE, userActive, settingOptions } from '../../../_constants_';
import { Button } from '@mui/material';

export const fields = [
  {
    name: 'name',
    label: 'Team_Seller Username',
    renderValue: (item) => `Team_${item.team_id} ${item.shortname || ''}`,
    sort:true,
    sortName:'name',
    width: '11rem'
  },
  {
    name: 'role',
    label: 'User - Role',
    renderValue: (item) => {
      const roles = item.groups.map(e => e.code);
      const role_name = item.groups.map(e => e.name).join(', ');
      if (roles.includes('teamlead')) {
        return (
          <div style={{ color: '#4FAD3B' }}><FontAwesomeIcon icon={faFlag} color="#4FAD3B" /> {role_name}</div>
        )
      }
      return role_name;
    },
    width: '7rem'
  },
  {
    name: 'email',
    label: 'Email Address',
    renderValue: (item) =>  <span className='line-clamp-1'>{item.email}</span>,
    sort:true,
    width: '16rem',
  },
  // {
  //   name: 'delta_spending_limit',
  //   label: 'Delta Spending Limit',
  //   renderValue: (item) => item.delta_spending_limit,
  //   sort:true,
  // },
  // {
  //   name: 'delta_spend',
  //   label: 'Delta Spend',
  //   renderValue: (item) => <span style={{ color: 'red' }}>{item.delta_spend}</span>,
  //   sort:true,
  //   width: '8rem'
  // },
  // {
  //   name: 'delta_remaining',
  //   label: 'Delta Remaining',
  //   renderValue: (item) => <span style={{ color: '#6FC46A' }}>{item.delta_remaining}</span>,
  //   sort:true,
  // },
  {
    name: 'status',
    label: 'Status',
    sort: false,
    renderValue: (item) => {
      return (<Button
        variant='contained'
        size='small'
        sx={{
          cursor: 'unset !important',
          borderRadius: '12px',
          fontSize: 14,
          color: COLOR_WHITE,
          borderColor: COLOR_WHITE,
          width: '150px',
          textTransform: 'none',
          backgroundColor: `${getButtonColor(item.active, userActive)}!important`
        }}
      >
        {convertActiveToStatus(item.active, 'label')}
      </Button>)
    }
  }
]

export const formFields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item?.team_id ? `Team_${item?.team_id?.team_id}` : ''
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address',
    disabled: true,
  },
  {
    type: 'text',
    name: 'groups',
    label: 'User - Role',
    disabled: true,
    renderValue: (item) => item?.groups?.length > 0 ? item?.groups.map(e => e.name).join(', ') : ''
  },
  {
    type: 'selectOptions',
    name: 'status',
    label: 'Status',
    options: settingOptions,
    placeholder: 'Select status',
    renderValue: (item) => item.status ? {value: item.status, label: item.status} : null,
    disabledFunc: ((item) => {
      return item.public_id === item.team_id?.owner_id?.public_id;
    })
  },
  {
    type: 'text',
    name: 'fullname',
    label: 'User - Fullname',
  },
  {
    type: 'text',
    name: 'shortname',
    label: 'User - Shortname',
    disabled: true
  },
  {
    type: 'text',
    name: 'facebook_profile_name',
    label: 'User - FB Profile Name',
  },
  {
    type: 'text',
    name: 'facebook_link',
    label: 'User - FB Link',
  },
  {
    type: 'text',
    name: 'phone',
    label: 'User - Phone number',
  }
]
