import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../_constants_/share-style';
import { Scrollbar } from '../index';
import { getButtonColor, getLabelById } from '../../_helpers_';
import {
  BG_COLOR_GREEN,
  COLOR_WHITE,
  PAGINATE_ARR,
  submitLinkStatus,
  productLinkOptions,
  COLOR_RED, PER_PAGE
} from '../../_constants_';
import EditProductLink from './modal-edit';
import { getProductLink, updateProductLinks } from '../../_actions_/product_link.actions';
import { useLocation } from 'react-router-dom';
import { clearData } from '../../_actions_';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function YourProductLinks() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authentication?.user);
  const listProductLink = useSelector((state) => state?.productLink?.listProductLink);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team_id: user?.team_id?.id,
    status: productLinkOptions[0].value
  });
  const [productLinkDetail, setProductLinkDetail] = useState(null);
  const [modalDetail, setModalDetail] = useState(false);
  const location = useLocation();
  const dataEditSuccess = useSelector((state) => state?.productLink?.dataEditSuccess);

  useEffect(() => {
    if (listProductLink) dispatch(clearData('listProductLink'));
  }, [location]);

  useEffect(() => {
    if (!listProductLink) {
      dispatch(getProductLink(queryObject));
    }
  }, [listProductLink]);

  useEffect(() => {
    if (dataEditSuccess) {
      dispatch(getProductLink(queryObject));
      dispatch(clearData('dataEditSuccess'));
    }
  }, [dataEditSuccess]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prevState) => ({
      ...prevState,
      page: 1,
      per_page: event.target.value
    }));
  };

  const handleOpenDetail = (detail) => {
    setProductLinkDetail(detail);
    setModalDetail(true);
  };

  const handleCloseDetail = () => {
    setModalDetail(false);
    setProductLinkDetail(null);
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          borderRadius: '8px',
          overflow: 'hidden',
          justifyContent: 'space-between'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0}
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                height: '45px',
                backgroundColor: COLOR_WHITE
              },
              minWidth: '300px',
              borderRight: 'none'
            }}
            value={queryObject.query}
            onChange={(event) => {
              setQueryObject((prev) => ({
                ...prev,
                search: event.target.value
              }));
            }}
          />
          <Button
            variant="contained"
            sx={{
              height: '45px',
              backgroundColor: BG_COLOR_GREEN,
              color: COLOR_WHITE,
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0
            }}
            onClick={() => {
              dispatch(getProductLink({ ...queryObject, page: 1 }));
            }}
          >
            Search
          </Button>
        </Box>
        <Select
          sx={{ minWidth: 250 }}
          value={queryObject.status}
          onChange={(event) => {
            setQueryObject((prevState) => ({
              ...prevState,
              status: event.target.value
            }));
            dispatch(getProductLink({ ...queryObject, status: event.target.value }));
          }}
        >
          {productLinkOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <Scrollbar>
          <Box className="mt-2" style={{ borderTop: '1px solid #ccc' }}>
            <Paper className={classes.root} style={{ position: 'relative' }}>
              <TableContainer className={classes.table}>
                <Table
                  stickyHeader
                  className={clsx(
                    classes.dynamic_col,
                    classes.dynamic_sticky,
                    classes.table,
                    'dynamic_col'
                  )}
                >
                  <TableHead className={classes.head_white}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '14rem' }}
                      >
                        <Tooltip title={'Product type'} arrow placement="top">
                          <Box className="line-clamp-1">Product type</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '15rem' }}
                      >
                        <Tooltip title={'Product URLs'} arrow placement="top">
                          <Box className="line-clamp-1">Product URLs</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '9rem' }}
                      >
                        <Tooltip title={'Target markets'} arrow placement="top">
                          <Box className="line-clamp-1">Target markets</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Ad account'} arrow placement="top">
                          <Box className="line-clamp-1">Ad account</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '14rem' }}
                      >
                        <Tooltip title={'Status'} arrow placement="top">
                          <Box className="line-clamp-1">Status</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Note'} arrow placement="top">
                          <Box className="line-clamp-1">Note</Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listProductLink &&
                      listProductLink.items &&
                      listProductLink.items.map((item) => {
                        return (
                          <TableRow key={item.id} onClick={() => handleOpenDetail(item)}>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item?.industry?.label}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      <a href={item.url} target={'_blank'}>
                                        {item.url ?? item.url_in_ad}
                                      </a>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {getLabelById(+item.target_market)}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item?.ad_account?.name}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                {item.status && item.status !== submitLinkStatus.warming ? (
                                  <Box p={1}>
                                    <Button
                                      sx={{
                                        cursor: 'unset !important',
                                        borderRadius: '12px',
                                        fontSize: 14,
                                        color: COLOR_WHITE,
                                        borderColor: COLOR_WHITE,
                                        width: '180px',
                                        textTransform: 'none',
                                        backgroundColor: `${getButtonColor(item.status, productLinkOptions)}!important`
                                      }}
                                      variant="outlined"
                                    >
                                      <Box className={classes.content_inline}>
                                        <Box className="text_2">
                                          <Box className={classes.text_ellipsis}>{item.status}</Box>
                                        </Box>
                                      </Box>
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: 1,
                                      p: 1
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        cursor: 'unset !important',
                                        borderRadius: '12px',
                                        fontSize: 14,
                                        color: COLOR_RED,
                                        borderColor: COLOR_RED,
                                        textTransform: 'none',
                                        backgroundColor: COLOR_WHITE
                                      }}
                                      variant="outlined"
                                    >
                                      <Box className={classes.content_inline}>
                                        <Box className="text_2">
                                          <Box className={classes.text_ellipsis}>{item.status}</Box>
                                        </Box>
                                      </Box>
                                    </Button>

                                    {/* submit link */}
                                    {item.status === submitLinkStatus.warming && (
                                      <Box className={classes.content_inline}>
                                        <Box className="text_2">
                                          <Box className={classes.text_ellipsis}>
                                            <Button
                                              sx={{
                                                borderRadius: '12px'
                                              }}
                                              variant="contained"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                const valid =
                                                  item.industry?.value &&
                                                  item.url_in_ad &&
                                                  item.target_market;

                                                if (valid) {
                                                  dispatch(
                                                    updateProductLinks(item.id, {
                                                      industry: item.industry.value,
                                                      url: item.url_in_ad,
                                                      target_market: +item.target_market,
                                                      status: submitLinkStatus.process
                                                    })
                                                  );
                                                }
                                              }}
                                            >
                                              Submit
                                            </Button>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>{item.note}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {listProductLink && (
              <TablePagination
                className="list-pagination"
                style={{
                  borderTop: '1px solid #d8d8d8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
                component="div"
                count={listProductLink?.total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={queryObject.page - 1}
                rowsPerPage={queryObject.per_page}
                rowsPerPageOptions={PAGINATE_ARR}
              />
            )}
          </Box>
        </Scrollbar>
      </Box>

      {productLinkDetail && (
        <EditProductLink
          modal={modalDetail}
          onClose={handleCloseDetail}
          productLink={productLinkDetail}
        />
      )}
    </Box>
  );
}
